<template>
    <div class="w-full dF mt-2">
        <a-form-model ref="newAccount" :model="newAccount">
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item prop="name" label="Name" required :rules="req('Please enter the Account Name')">
                        <a-input size="large" v-model="newAccount.name" placeholder="Account Name"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Company" prop="company">
                        <a-input size="large" v-model="newAccount.company" placeholder="Company"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>

            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item prop="jobTitle" label="Position">
                        <a-input size="large" placeholder="Job Title" v-model="newAccount.jobTitle"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-popover style="cursor: pointer;" trigger="click" overlayClassName="popoverStyle" placement="bottom">
                        <div slot="content">
                            <div class="popoverContent" v-for="(type,typeI) in mobileType" :key="type+typeI" @click="newAccount.phone.type = type">
                                {{type}}
                            </div>
                        </div>
                        <div style="padding-top: 5px; line-height: 1; width: 100px;">
                            <div style="margin-bottom: .5rem; color: #40454c;">{{newAccount.phone.type}} <a-icon type="caret-down" class="ml-2" /></div>
                        </div>
                    </a-popover>
                    <!-- <a-form-model ref="newPhoneNumber" :model="newPhoneNumber"> -->
                        <a-form-model-item prop="phone.value">
                            <a-input size="large" placeholder="Phone Number" v-model="newAccount.phone.value" />
                        </a-form-model-item>
                    <!-- </a-form-model> -->
                </a-col>

            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item label="Email" required prop="email" :rules="req('Please enter the Email')">
                        <a-input size="large" v-model="newAccount.email" placeholder="Email"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Website" prop="website">
                        <a-input size="large" v-model="newAccount.website" placeholder="Website"></a-input>
                    </a-form-model-item>
                </a-col>
            </a-row>
            <a-row :gutter="16">
                <a-col :span="12">
                    <a-form-model-item label="Address" prop="address">
                        <a-input size="large" v-model="newAccount.address" placeholder="Address"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Office Number" prop="officePhone">
                        <a-input size="large" v-model="newAccount.officePhone" placeholder="Office Number"></a-input>
                    </a-form-model-item>
                </a-col>
                <a-col :span="12">
                    <a-form-model-item label="Fax" prop="fax">
                        <a-input size="large" v-model="newAccount.fax" placeholder="Fax"></a-input>
                    </a-form-model-item>
                </a-col>
				<a-col :span="12">
                    <a-form-model-item prop="appointment">
						<span slot="label">
							Show Appointment Widget URL?
							<a-tooltip overlayClassName="change-tooltip-color">
								<span slot="title">
									To set your appointment widget URL, visit the <span @click="$emit('goto', 8)" style="color: var(--orange); cursor: pointer;">Meetings</span> tab
								</span>
								<a-icon type="question-circle-o" style="color: var(--orange);" />
							</a-tooltip>
						</span>
                        <a-switch size="large" v-model="newAccount.appointment" @change="appointmentSwitch"></a-switch>
                    </a-form-model-item>
                </a-col>
            </a-row>

			<hr />
			<h5 class="mb-4">Font and Color Settings</h5>

			<a-row :gutter="16">
				<a-col :span="12">
                    <a-form-model-item prop="fontFamily">
						<span slot="label">
							Font Family
							<a-tooltip overlayClassName="change-tooltip-color">
								<span slot="title">
									The selected font will also be used in your email templates.
								</span>
								<a-icon type="question-circle-o" style="color: var(--orange);" />
							</a-tooltip>
						</span>
                        <a-select size="large" v-model="newAccount.fontFamily">
							<a-select-option value="" disabled>Select a font family</a-select-option>
							<a-select-option value="Arial">Arial</a-select-option>
							<a-select-option value="Times">Times New Roman</a-select-option>
							<a-select-option value="Helvetica">Helvetica</a-select-option>
							<a-select-option value="Georgia">Georgia</a-select-option>
							<a-select-option value="Tahoma">Tahoma</a-select-option>
							<a-select-option value="Verdana">Verdana</a-select-option>
							<a-select-option value="Trebuchet MS">Trebuchet MS</a-select-option>
						</a-select>
                    </a-form-model-item>
                </a-col>
				<a-col :span="12">
                    <a-form-model-item label="Field Label Color" prop="fieldColor">
                        <ColorPicker v-model="newAccount.fieldColor" />
                    </a-form-model-item>
                </a-col>
			</a-row>
			<a-row :gutter="16">
				<a-col :span="12">
                    <a-form-model-item label="Appointment Button Background Color" prop="appointmentBgColor">
                        <ColorPicker v-model="newAccount.appointmentBgColor" />
                    </a-form-model-item>
                </a-col>
				<a-col :span="12">
                    <a-form-model-item label="Appointment Button Text Color" prop="appointmentColor">
                        <ColorPicker v-model="newAccount.appointmentColor" />
                    </a-form-model-item>
                </a-col>
				<a-col :span="12">
                    <a-form-model-item label="Appointment Button Text" prop="appointmentText">
                        <a-input size="large" v-model="newAccount.appointmentText" placeholder="Appointment Text"></a-input>
                    </a-form-model-item>
                </a-col>
			</a-row>
		</a-form-model>
    </div>
</template>

<script>
import ColorPicker from '@/components/common/ColorPicker'

export default {
	components: {
		ColorPicker,
	},
	computed: {
		previewAccount() {
			return this.$store.state.contacts.previewAccount
		},
		account() {
			return this.$store.state.contacts.allSettings?.userApp?.options?.account || {}
		},
		settings() {
			return this.$store.state.contacts.allSettings.app
		},
		userSettings() {
			return this.$store.state.contacts.allSettings.userApp
		},
		vendor() {
			let vendorKeys = Object.keys(this.$store.state.contacts.vendors)
			let vendorValues = Object.values(this.$store.state.contacts.vendors)

			let integrationKeys = Object.keys(this.$store.state.contacts.integrations)
			let integrationValues = Object.values(this.$store.state.contacts.integrations)

			if (!vendorValues?.length) {
				return {};
			}

			// Find vendor values
			const gmailValue = vendorValues[vendorKeys.findIndex(x => x.includes('gmail'))];
			const outlookValue = vendorValues[vendorKeys.findIndex(x => x.includes('outlook'))];
			const officeValue = vendorValues[vendorKeys.findIndex(x => x.includes('office365'))];

			// Check Gmail first
			if (gmailValue?.active) {
				return gmailValue;
			}

			// Check Outlook with Zoom integration
			if (outlookValue?.active && integrationValues?.length) {
				const zoomValue = integrationValues[integrationKeys.findIndex(x => x.includes('zoom'))];
				if (zoomValue?.active) {
					return zoomValue;
				}
			}

			// Check Office365 with Zoom or Teams integration
			if (officeValue?.active && integrationValues?.length) {
				const zoomValue = integrationValues[integrationKeys.findIndex(x => x.includes('zoom'))];
				if (zoomValue?.active) {
					return zoomValue;
				}

				const teamsValue = integrationValues[integrationKeys.findIndex(x => x.includes('teams'))];
				if (teamsValue?.active) {
					return teamsValue;
				}
			}

			return {};
		}
	},
	data() {
		return {
			newAccount: {},
			mobileType: [
				'Work', 'Mobile'
			],
			loading: false,
		}
	},
	watch: {
		previewAccount: {
			handler(val) {
				let { name, jobTitle, logo, phone, email, company, officePhone, socials, fax, website, address, template, appointment, fieldColor, fontFamily, appointmentBgColor, appointmentColor, appointmentText } = this.newAccount
				if (val.name != name || val.jobTitle != jobTitle || val.logo != logo || val.phone.type != phone.type || val.phone.value != phone.value || val.email != email || val.company != company || val.officePhone != officePhone || val.socials != socials || val.fax != fax || val.website != website || val.address != address || val.template != template || val.appointment != appointment || val.fieldColor != fieldColor || val.fontFamily != fontFamily || val.appointmentBgColor != appointmentBgColor || val.appointmentColor != appointmentColor || val.appointmentText != appointmentText) {
					this.newAccount = val
				}
			},
			deep: true
		},
		newAccount: {
			handler(val) {
				this.$store.commit('PREVIEW_ACCOUNT', val)
			},
			deep: true
		},
		'$route': {
			immediate: true,
			handler(val) {
				this.$store.commit('PREVIEW_ACCOUNT', {})
			}
		},
	},
	methods: {
		appointmentSwitch(e) {
			if (!this.settings?.options?.appointments?.widgetUrl) {
				this.newAccount.appointment = false
				this.$message.error('Please set your appointment widget URL in the Meetings tab first.')
			} else if (!(this.vendor && this.vendor.active)) {
				this.newAccount.appointment = false
				this.$message.error('Please integrate your calendar first.')
			}
		},
		cancel() {
			this.newAccount = JSON.parse(JSON.stringify(this.$store.state.contacts.allSettings.userApp.options.account))
		},
		req: msg => ({ required: true, message: msg }),

		saveAccount() {
			this.$refs.newAccount.validate(async valid => {
				if (valid) {
					await this.updateUserSettings();
				} else {
					return false;
				}
			});
		},

		async updateUserSettings() {
			let settings = JSON.parse(JSON.stringify(this.userSettings))
			settings.options.account = this.newAccount
			this.loading = true
			try {
				const { data } = await this.$api.post('/settings/:instance/contacts/user', settings)
				this.loading = false
				this.$store.commit('UPDATE_USER_ALLSETTINGS', data)
			} catch (err) {
				this.loading = false
				this.$toastError(err, 'Error while updating account settings. Please try again')
			}
		},

		clearAllFields() {
			this.newAccount = {
				...this.previewAccount,
				name: '',
				company: '',
				jobTitle: '',
				phone: {
					type: 'Work',
					value: ''
				},
				email: '',
				website: '',
				address: '',
				officePhone: '',
				fax: '',
				appointment: false,
				fieldColor: '#f7941e',
				fontFamily: 'Arial',
				appointmentBgColor: '#f7941e',
				appointmentColor: '#FFFFFF',
				appointmentText: 'BOOK A MEETING',
			}

			this.updateUserSettings();
		}
	},
	created() {
		this.newAccount = JSON.parse(JSON.stringify(this.account))

		// Apply default values for missing account properties

		Object.assign(this.newAccount, {
			fieldColor: this.newAccount.fieldColor ?? "#f7941e",
			appointmentColor: this.newAccount.appointmentColor ?? "#FFFFFF",
			appointmentBgColor: this.newAccount.appointmentBgColor ?? "#f7941e",
			appointmentText: this.newAccount.appointmentText ?? "BOOK A MEETING",
			fontFamily: this.newAccount.fontFamily ?? "Arial"
		});

		this.$store.commit('PREVIEW_ACCOUNT', this.newAccount)
	},
}
</script>

<style lang="scss">
	.change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
        background:#3F3356;
    }
    .popoverStyle .ant-popover-inner-content {
        padding: 0;
        background-color: white;
        border-radius: 20px;
    }
</style>

<style lang="scss" scoped>
    .popoverContent{
        height: 35px;
        width: 60px;
        line-height: 35px;
        padding-left: 10px;
    }
    .popoverContent:hover{
        background-color: var(--off-white-light);
        cursor: pointer;
    }
</style>
